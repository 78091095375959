/* eslint-disable */
import React from 'react'
import loadable from '@loadable/component'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'
import ReactDOM from 'react-dom'
import {HorizontalBar} from 'react-chartjs-2'
//import '../components/alice-carousel.scss'

//import Typewriter from 'typewriter-effect';

import { defaults } from 'react-chartjs-2';

import logo from '../img/logo-ev1a.svg'

const OfferRoll2 = loadable(() => import('../components/OfferRoll2'));
const BlogRoll2 = loadable(() => import('../components/BlogRoll2'));
const BriefCall = loadable(() => import('../components/BriefCall'));
const SliderOpinions = loadable(() => import('../components/SliderOpinions'));
const PartnerSlider = loadable(() => import('../components/PartnerSlider'));
const AliceCarousel = loadable(() => import('react-alice-carousel'));

//import FormMsg from '../components/FormMsg'

defaults.global.defaultFontColor = '#36b3d2';


let data2={
  labels: ['','2026','2025','2024','2023','2022','2021','2020','2019','2018','2017','2016','2015','2014',''],
 datasets: [
   {
     label: 'e-commerce 2014-2026',
     fill: true,
     backgroundColor: ['#1183c8','#96edf0','#30c0ca','#41aec9','#3dbbd5','#1db4cb','#2ea1b8','#36b3d2','#1e7b9e','#1183c8','#3dbbd5','#30c0ca','#41aec9','#3dbbd5'],
     borderColor: 'rgba(113, 101, 248,0.75)',
     hoverRadius: 10,
     borderWidth: 0,
     hoverBackgroundColor: '#111111',defaultFontColor: '#111111',
     data: [
            0,
            8148,
            7528,
            6913,
            6310,
            5717,
            5211,
            4248,
            3351,
            2982,
            2382,
            1845,
            1548,
            1336,
            0,
          ]
   }

 ]
}




export default class Index extends React.Component {

  componentDidMount(){

      var lazyBackgrounds = [].slice.call(
        document.querySelectorAll(".lazy-background")
      );

      if (
        "IntersectionObserver" in window &&
        "IntersectionObserverEntry" in window &&
        "intersectionRatio" in window.IntersectionObserverEntry.prototype
      ) {
        let lazyBackgroundObserver = new IntersectionObserver(function (
          entries,
          observer
        ) {
          entries.forEach(function (entry) {
            if (entry.isIntersecting) {
              entry.target.classList.add("visible");
              lazyBackgroundObserver.unobserve(entry.target);

            }
          });
        });
        lazyBackgrounds.forEach(function (lazyBackground) {
          lazyBackgroundObserver.observe(lazyBackground);
        });
      }
    ;
    //animateIcons();
  }

render() {
  return (
    <>
    <Helmet titleTemplate="Agencja copywriterska Wrocław | e-multicontent.pl">
      <title>Agencja copywriterska Wrocław | Dolnośląskie | e-multicontent.pl</title>
      <meta name="description" content={'✍🏻 Piszemy dla 🛒 e-commerce w języku polskim i w innych językach uwzględniając SEO - opisy produktowe, kategorii, wpisy blogowe, treści na www.'}/>
      <meta name='image' content={'https://e-multicontent.pl/img/meta/home-meta.jpg'} />
      <meta name='keywords' content={'copywriting, copywriterzy, marketing, agencja copywriterska, Wrocław, zespół copywriterów, praca, biznes, treści, content, tłumaczenia, SEO, blog, teksty, artykuły, grafika, video, strony internetowe, sklepy internetowe, marketplace, e-commerce'} />

      <link rel="alternate" href="https://e-multicontent.com" hrefLang="en-gb" />
      <link rel="alternate" href="https://e-multicontent.com" hrefLang="en-us" />
      <link rel="canonical" href="https://e-multicontent.pl/"  />

      <meta property="og:description" content={'✍🏻 Piszemy dla 🛒 e-commerce w języku polskim i w innych językach uwzględniając SEO - opisy produktowe, kategorii, wpisy blogowe, treści na www.'}/>
      <meta property="og:title" content={'Agencja copywriterska Wrocław | Dolnośląskie | e-multicontent.pl'} />
      <meta property="og:image" content={'https://e-multicontent.pl/img/meta/home-meta.jpg'} />
      <meta property="og:url" content={'https://e-multicontent.pl'}/>

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="e-multicontent.pl" />
      <meta property="twitter:url" content="https://e-multicontent.pl/" />
      <meta name="twitter:title" content="Agencja copywriterska Wrocław | Dolnośląskie | e-multicontent.pl" />
      <meta name="twitter:description" content="✍🏻 Piszemy dla 🛒 e-commerce w języku polskim i w innych językach uwzględniając SEO - opisy produktowe, kategorii, wpisy blogowe, treści na www." />
      <meta name="twitter:image" content="https://e-multicontent.pl/img/apple-touch-icon.png" />

      <script type='application/ld+json'>
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://e-multicontent.pl"
        }]
      })}
      </script>
    </Helmet>
  <Layout>
  <div id="hero-home">
  {/* image module */}
    <div
      id="up"
      className="full-width-image margin-top-0"
      style={{
        backgroundAttachment: `contain`,
        transform: 'scale(1)',
        top:'0px',
        backgroundRepeat:'no-repeat',
        zIndex: '-5',
        width: '100%'

      }}
    ></div>
      <div
        className="mright"
        style={{
          display: 'flex',
          position: 'absolute',
          left: '10%',
          lineHeight: '1',
          justifyContent: 'space-around',
          flexDirection: 'column',
          top: '100px',
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
          borderRadius: '15px'
        }}
      >

      <a
        href="/#oferta"
        rel="noopener noreferrer"
        style={{borderRadius: '5px',backgroundColor:'rgba(0,0,0,0.0)'}}
      >
        <img src={logo} alt="SEO copywriting e-commerce opisy produktów w językach obcych, copywriter Wrocław - e-multicontent.pl"
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen "
          style={{
            color:'#252529',
            lineHeight: '1',
            padding: '0.25em',
            width: '400px',
            marginLeft:'-33px',
            marginBottom:'-2px',
          }}
        /><h1
        className="h1title"
        style={{
          color:'#4a4a4a',
          lineHeight: '1.3',
          padding: '0.35em',
          fontSize:'1.15em',
          maxWidth:'450px',
        }}>
        Copywriting Wrocław - Agencja copywritingu e-multicontent.

        </h1>
        <br></br>


        <h2
          style={{
            color: '#4a4a4a',
            lineHeight: '1.2',
            padding: '0.35em',
            fontSize: '.9em',
            textAlign: 'left',
            maxWidth:'90%',
          }}
        >
        <strong>Zespół skutecznych copywriterów.</strong>
        <br />
        Tworzymy <strong>content dla e-commerce</strong>.
        <br />
        W wielu językach. Uwzględniając <strong>SEO</strong>.
        </h2>
        <br></br>

        <br></br>
        </a>










      </div>


    {/*Content*/}
    <section  className="section section--gradient"
    style={{padding:'0px',margin:'0px',width:'100%',left:'0%'}}
     >


     <p className="ux" align="center" id="oferta"><a className="btnoutline" href="/#nasza-oferta">⇩</a></p>
     <div className="columns"
     style={{marginTop:'0px'}}
     >
       <div className="column is-12">

         <h3 id="nasza-oferta" className="has-text-weight-semibold is-size-2"
         style={{marginLeft:'7.75%',paddingTop:'50px',maxWidth:'85%',display:'flex',flexWrap:'wrap'}}
         >Treści, które&nbsp;<span style={{color:'#36b3d2'}}>zbudują Twój biznes.</span>
         </h3>
         <br />
         <div style={{marginLeft:'7.75%',maxWidth:'75%'}}>
         <p style={{fontSize:'1.5em'}}><strong>Agencja copywriterska</strong> <b><span style={{color:'#36b3d2'}}>e</span>-multicontent</b> - poznaj naszą ofertę: </p>
         <p>Od opisów produktów dostosowanych pod seo po copywriting w językach obcych do prowadzenia firmowych blogów oraz sklepów internetowych i tworzenia <Link to="/oferta/grafika-i-video-dla-e-commerce/" className="dedlink">contentu</Link> na ich użytek.
         E-multicontent to <strong>wielojęzyczna agencja contentowa</strong>, specjalizującą się w webwritingu i <Link to="/oferta/pakiety-abonamentowe-dla-e-commerce/" className="dedlink">treściach dla branży e-commerce</Link>.<br /><br /> Jeżeli potrzebujesz skutecznych <Link className="dedlink" to="/oferta/copywriting-pl/">opisów produktów i kategorii do swojego sklepu internetowego</Link>, interesujących <Link className="dedlink" to="/oferta/copywriting-pl/blogi-dla-e-commerce/">treści blogowych</Link> czy też angażujących <Link to="/oferta/copywriting-pl/" className="dedlink">tekstów na firmową stronę www</Link> to doskonale trafiłeś! Piszemy w aż <strong>35 różnych językach</strong>, działając równolegle w każdym z nich! Nie tracisz więc czasu na tłumaczenia - dostajesz komplet materiałów w tym samym czasie.
         </p>
         </div>
         {/*oferta*/}

         <OfferRoll2 />
         <div className="has-text-centered">
           <Link className="btn" to="/oferta/" style={{fontSize:'1.5em'}}>
             Poznaj ofertę &nbsp; &nbsp;
             <img width="15px" height="22px" style={{verticalAlign:'middle', marginRight:'5px'}}   alt="blog o copywritingu e-commerce marketplace seo i tłumaczeniach" src='/img/angle-right.svg'/>
           </Link>
           <br /><br />
         </div>
       </div>
     </div>

        <BriefCall />

        <div style={{backgroundColor:'#ffffff',width:'100%',padding:'50px',marginLeft:'0px'}}>
                <div className="content" style={{marginTop:'50px',marginBottom:'50px',marginLeft:'5%'}}>
                    <h2 className="is-size-2">
                    Zobacz, jak <b style={{color:'#36b3d2'}}>możemy Ci pomóc</b>.
                    </h2>
                    <h3 style={{fontSize:'1em',width:'85%',fontWeight:'400'}}>Tworzymy e-multicontent dla e-commerce, oferujemy znacznie więcej niż <strong>agencje copywriterskie</strong>, wykorzystując odpowiednie narzędzia do odpowiednich zadań: <b>od opisów produktów po treści na
                    blogi</b>. W języku polskim i w językach obcych, zoptymalizowane pod kątem SEO. W paru sowach ujmując: mocno rozbududowane, wysokiej jakości <strong>usługi copywriterskie</strong>.</h3>
                    <p style={{fontSize:'1em'}}>Dlaczego warto z nami współpracować?</p>
                </div>

                <br></br>
                <br></br>

                <div style={{marginLeft:'5%',marginRight:'5%',marginBottom:'50px'}}>
                <div id="atuty" className="columns" style={{textAlign:'center',width:'100%',margin:'auto'}}>

                  <div className="column">
                  <div style={{textAlign:'center'}}>
                    <img className="atut" loading="lazy" src="/img/fa-icons/users-solid.svg" style={{margin:'10px'}} width="50px" height="50px" alt="zespół doświadczonych copywriterów znających SEO i e-commerce"/>
                    </div>
                    <br></br>
                    <p style={{fontSize:'14px'}}>Jesteśmy zespołem <strong>doświadczonych copywriterów - native speakerów</strong> z wiedzą marketingową</p>

                  </div>

                  <div className="column">
                  <div style={{textAlign:'center'}}>
                    <img className="atut" loading="lazy" src="/img/fa-icons/glasses-solid.svg" style={{margin:'10px',}} width="50px" height="50px" alt="treści pod seo na strony i sklepy internetowe"/>
                    </div>
                    <br></br>
                    <p style={{fontSize:'14px'}}> Przygotowujemy od podstaw i <strong>tłumaczymy treści w kilkudziesięciu językach</strong> </p>

                  </div>

                  <div className="column">
                  <div style={{textAlign:'center'}}>
                    <img className="atut" loading="lazy" src="/img/fa-icons/chess-solid.svg" style={{margin:'10px',}} width="50px" height="50px" alt="Tłumaczenia w ponad 20 językach i content markteting SEO dla stron internetowych "/>
                    </div>
                    <br></br>
                    <p style={{fontSize:'14px'}}> <strong>Tworzymy i wdrażamy</strong> strategię content marketingową, koordynując projekty <strong>od A do Z</strong> </p>

                  </div>

                  <div className="column">
                  <div style={{textAlign:'center'}}>
                    <img className="atut" loading="lazy" src="/img/fa-icons/quote-right-solid.svg" style={{margin:'10px',}} width="50px" height="50px" alt="Unikalne teksty - copywriting SEO na strony internetowe"/>
                    </div>
                    <br></br>
                    <p style={{fontSize:'14px'}}> Tworzymy <strong>unikalne teksty, bez duplikowania treści</strong> </p>

                  </div>

                  <div className="column">
                  <div style={{textAlign:'center'}}>
                    <img className="atut" loading="lazy" src="/img/fa-icons/text-height-solid.svg" style={{margin:'10px',}} width="50px" height="50px" alt="Optymalizacja treści pod seo - copywriting"/>
                    </div>
                    <br></br>
                    <p style={{fontSize:'14px'}}> Posługujemy się <strong>językiem korzyści</strong>, bez schematów i klisz językowych </p>

                  </div>
                </div>

                <div className="columns" style={{textAlign:'center',margin:'auto',marginTop:'25px'}}>

                  <div  className="column">
                  <div style={{textAlign:'center'}}>
                    <img className="atut" loading="lazy" src="/img/fa-icons/spell-check-solid.svg" style={{margin:'10px',}} width="50px" height="50px" alt="Doświadczeni copywriterzy native - speakerzy"/>
                    </div>
                    <br></br>
                    <p style={{fontSize:'14px'}}> Wyróżnia nas <strong>poprawność gramatyczna, ortograficzna i stylistyczna</strong> w każdym języku </p>

                  </div>

                  <div  className="column">
                  <div style={{textAlign:'center'}}>
                    <img className="atut" loading="lazy" src="/img/fa-icons/map-pin-solid.svg" style={{margin:'10px',}} width="50px" height="50px" alt="Wielojęzyczny zespół praca copywriting / copywriter seo"/>
                    </div>
                    <br></br>
                    <p style={{fontSize:'14px'}}>Dostosowujemy content do <strong>wymogów konkretnego rynku i oczekiwań odbiorców</strong> w danym kraju</p>

                  </div>

                  <div  className="column">
                  <div style={{textAlign:'center'}}>
                    <img className="atut" loading="lazy" src="/img/fa-icons/store-solid.svg" style={{margin:'10px',}} width="50px" height="50px" alt="Opisy produktów w języku polskim dla stron internetowych pod seo"/>
                    </div>
                    <br></br>
                    <p style={{fontSize:'14px'}}> <strong>Doskonale znamy</strong> specyfikę e-commerce i marketplace </p>

                  </div>

                  <div  className="column">
                  <div style={{textAlign:'center'}}>
                  <img className="atut" loading="lazy" src="/img/fa-icons/search-location-solid.svg" style={{margin:'10px',}} width="50px" height="50px" alt="wielojęzyczność treści dla stron internetowych - copywriting seo"/>
                  </div>
                    <br></br>
                    <p style={{fontSize:'14px'}}> <strong>Optymalizujemy teksty pod kątem SEO</strong>, nie tracąc przy tym na jakości </p>

                  </div>

                  <div className="column">
                  <div style={{textAlign:'center'}}>
                    <img className="atut" loading="lazy" src="/img/fa-icons/globe-europe-solid.svg" style={{margin:'10px',}} width="50px" height="50px" alt="copywriterzy z całego świata doświadczeni w seo i e-commerce"/>
                    </div>
                    <br></br>
                    <p style={{fontSize:'14px'}}> Działamy <strong>lokalnie i globalnie</strong> </p>
                  </div>

                  <br></br>
                  </div>

                </div>






                </div>

                <div style={{backgroundColor:'#F5F5F2',textAlign:'center',color:'#222222'}}>


                {/* wykres */}
                <br />
                <h3 className='has-text-weight-semibold is-size-2'><span style={{color:'#36b3d2'}}>Co mówią o nas</span>&nbsp;klienci?</h3>
                <SliderOpinions />
                <br></br>








                <h4 style={{paddingBottom:'10px'}}> Globalna sprzedaż detaliczna w handlu e-commerce 2014-2026.<sup>*</sup></h4>
                <br></br>

                <div style={{ paddingLeft:'10%',paddingRight:'10%',borderRadius: '20px',left:'0%',width:'100%',margin:'0',color:'#222222'}}>




                <HorizontalBar
                  data={data2}
                  width={70}
                  height={35}
                  options={{ maintainAspectRatio: true, }}

                />

              <sub> <br></br><sub><i> <sup>*</sup>Opublikowane przez Stephanie Chevalier, 21 września 2022 na portalu statista.com</i></sub>
                <br></br><sub><i>Liczone w milliardach dolarów.</i></sub>
                <br></br><br></br><br></br>
                </sub>






          </div>







        </div>















    </section>






    {/*BREAK 1*/}



{/*PORTFOLIO/BLOG*/}

<div>
    <div className="column is-12" style={{textAlign:'right'}}>
      <img loading="lazy" src="/img/portfoliomini.svg" id="portfolio - copywriting w wielu językach seo / e-commerce"  style={{marginRight:'-20px'}}  width="80%" height="80%" alt="portfolio e-multicontent tłumaczenia SEO"/>
      <h3 className="has-text-weight-semibold is-size-2">
        Sprawdź nasze <Link to="/portfolio/" style={{color:'#36b3d2'}}><b>portfolio</b></Link>:
      </h3>
      <p>Zapoznaj się z danymi. </p>

      <div className="column is-12" style={{marginBottom:'50px',marginTop:'20px',textAlign:'right'}} >
      <Link to="/portfolio/" className="btn" style={{fontSize:'1.5em'}} >
      Zobacz nasze realizacje &nbsp; &nbsp;
      <img width="15px" height="22px" style={{verticalAlign:'middle', marginRight:'5px'}}   alt="portfolio usług copywriterskich SEO / strony internetowe" src='/img/angle-right.svg'/>
      </Link>








      </div>

    </div>
      <br></br>

    <div className="column">
      <img loading="lazy" src="/img/blogmini.svg" style={{marginLeft:'-20px'}}  width="80%" height="80%"  alt="Blog: SEO tłumaczenie e-commerce copywriting"/>
      <div style={{marginLeft:'5%',marginRight:'5%'}}>
      <h3 className="has-text-weight-semibold is-size-2">
        Zajrzyj na naszego <Link to="/blog/" style={{color:'#36b3d2'}}><b>bloga</b></Link>:
      </h3>
      <BlogRoll2 />
      </div>
      <br />
      <div className="column is-12 has-text-centered">
        <Link className="btn" to="/blog/" style={{fontSize:'1.5em'}}>
          Przeczytaj więcej &nbsp; &nbsp;
          <img width="15px" height="22px" style={{verticalAlign:'middle', marginRight:'5px'}}   alt="blog o copywritingu e-commerce marketplace seo i tłumaczeniach" src='/img/angle-right.svg'/>
        </Link>
      </div>
    </div>
    <br></br>

    </div>




















    <div style={{marginLeft:'5%'}}>
    <h3 className="is-size-2"><b>Zapisz się, aby otrzymać naszego</b> <Link style={{color:'#36b3d2',fontWeight:'800'}} to="/ebook/"> e-booka</Link>:</h3>
    <p style={{fontSize:'1em'}}>Jak zwiększyć sprzedaż w e-sklepie? Skuteczne opisy produktów w pięciu krokach.</p>
    </div>
    <br></br>
    <div className="one lazy-background" style={{backgroundColor:'#36b3d2',
    backgroundPosition:'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',}}>


    <div style={{marginBottom:'0px',height:'320px',textAlign:'center',padding:'100px'}}>

      <Link className="btnblog" to="/ebook/" style={{padding:'30px'}}>
        <span role="img" alt="pobierz e-book o e-commerce" aria-label="ebook-emoji">Zapisz się</span><br></br>
      </Link>
    </div>

    </div>



{/*kontakt
<div style={{backgroundColor:'rgb(245, 245, 245)',padding:'5%'}}>


<div className="is-size-2" style={{fontWeight:'800'}}>
<span style={{color:'#36b3d2'}}>Skontaktuj się</span> z nami:
</div>
<br />
<br />


<div className="columns" style={{margin:'auto'}}>

<div className="column">
<h3 style={{fontSize:'1em',textAlign:'center'}}>Chcesz się z nami skontaktować?<br /> Wypełnij formularz poniżej, możesz również załączyć plik.</h3>
<br />
<FormMsg/>
</div>

<div className="column" style={{position:'relative',textAlign:'center'}}>
<h3 style={{fontSize:'1em'}}>Chcesz otrzymać ofertę dla siebie?<br />Wypełnij brief.</h3>
<br /><br />
<a className="btn" style={{fontSize:'1.5em'}}
href="https://docs.google.com/forms/d/e/1FAIpQLSdkSrYDjdS0qddoWvk7555qSST6N5Z4qpBcrVWoy7aZQ2_SqA/viewform"
target="_blank" rel="noopener noreferrer">Brief e-multicontent &nbsp; &nbsp;
<img width="15px" height="auto"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="brief" src='/img/angle-right.svg'/></a>
<br /><br />
<br /><br />
<img src="/img/kontakt.jpg" style={{maxWidth:'550px'}} loading="lazy" alt="brief" width="100%" height="100%" />
</div>

</div>

<div className="columns" style={{marginTop: '50px', paddingBottom: '70px',marginLeft:'3%',textAlign:'center' }}>

<h3 style={{marginLeft:'3%', color:'#222222'}}>Chcesz wysłać maila?</h3>&nbsp;&nbsp;&nbsp;&nbsp;
&nbsp;<h4><a href="mailto:biuro@e-multicontent.pl" rel="noopener noreferrer" className="btn"  style={{marginTop:'-30px',marginBottom:'20px'}} target="_blank">biuro@e-multicontent.pl</a></h4>


<h3 style={{marginLeft:'3%', color:'#222222'}}>Czy może wolisz porozmawiać?</h3>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h4><a href="tel:+48713074517" rel="noopener noreferrer" style={{marginTop:'-30px'}}  className="btn" target="_blank">+48 71 307 45 17</a></h4>
</div>
</div>


*/}








<div  className="box" style={{marginBottom:'0px'}}>
    <div style={{textAlign:'center',padding:'20px'}} >
    <h3 style={{fontSize:'2em',fontWeight:'800'}}>Zaufali nam, między innymi:</h3>
    </div>
    <div style={{ textAlign: 'center',padding:'40px',borderRadius:'20px',zIndex:'100', color:' #17132b',marginBottom:'20px'}} >

    <AliceCarousel autoPlay infinite disableButtonsControls={true} items={items2} autoPlayInterval="4500"
      responsive={{
        0: {
        items: 1,
    },
    1024: {
        items: 3,
    }}}
      >
      </AliceCarousel>

    </div>




    <h3 align="center" style={{fontSize:'2em',fontWeight:'800'}}><Link className="dedlink" to="/partnerzy/">Partnerzy:</Link></h3>
    <br></br>



    <div style={{ textAlign: 'center',padding:'40px',borderRadius:'0px', marginBottom:'20px', position:'relative'}} >
    <PartnerSlider />
    </div>


    <br></br>


    <p style={{margin:'0% 5%'}}>Sprzedaż internetowa to jedna z najbardziej dynamicznie rozwijających się dziedzin gospodarki. Ludzie na całym świecie pokochali zakupy online, a rosnąca z miesiąca na miesiąc liczba e-sklepów wielu z nim pozwala całkowicie zrezygnować z wizyt w tradycyjnych marketach. Jeżeli Ty prowadzisz sklep internetowy to wiesz, jak duży potencjał tkwi w e-commerce, ale także, jak trudno wyróżnić się na tle licznej konkurencji.
    <br /><br />
    W e-multicontent znamy świat e-commerce od podszewki, dlatego też z chęcią pomożemy Ci go podbić. Po pierwsze - przygotujemy dla Ciebie unikalne opisy produktów do sklepu internetowego po polsku, angielsku, niemiecku i w dowolnym innym języku. Po drugie - opracujemy zoptymalizowane pod SEO opisy kategorii i podkategorii, które zwiększą widoczność Twojego e-sklepu w sieci. Wreszcie po trzecie - zadbamy o Twój blog, regularnie publikując na nim wartościowe i użyteczne dla Twoich klientów wpisy. Dzięki temu nie tylko wzrośnie Twoja pozycja w wyszukiwarkach i zyskasz nowych odbiorców dla swoich produktów (na rynku polskim i zagranicznym), ale także będziesz mógł się cieszyć opinią eksperta w swojej branży i statusem wiarygodnego, sprawdzonego sprzedawcy.
    <br /><br />
    <Link className="dedlink" to="/kontakt/">Skontaktuj się z nami</Link>, a przeprowadzimy dla Ciebie <strong>darmowy, wstępny audyt treści i zaproponujemy działania contentowe</strong>, które pomogą Ci odnieść sukces w dziedzinie e-commerce.
    </p>
    <br /><br />
    <div style={{textAlign:'center'}}>
      <a className="btnoutline" href="/#up">
      ⇪

      </a>



    <br></br>


    </div>
    </div>




    <div
      className="full-width-image has-text-centered margin-top-0 two lazy-background"
      style={{
        backgroundPosition: `center right`,
        backgroundAttachment: `fixed`,
        height:'400px',
        width:'100%',
      }}
    >
    </div>

  </div>
  </Layout>
  </>
)
}

}




const items2 = [
  <a className="partners" href="https://www.dpd.com/pl/pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/dpd-logo.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="DPD"/></a>,
  <a className="partners" href="https://www.bimago.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/bimago-logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="Bimago"/></a>,
  <a className="partners" href="https://borntoflytravel.com/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/born2fly-logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="Borntofly"/></a>,
  <a className="partners" href="https://greenpress.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/greenpress-logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="Greenpress"/></a>,
  <a className="partners" href="https://www.karko.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/karko-logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="Karko"/></a>,
  <a className="partners" href="https://cleantle.com" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/cleantech-logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="CleanTech"/></a>,
  <a className="partners" href="https://orsay.com" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/orsay-logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="Orsay"/></a>,
  <a className="partners" href="https://www.timsa.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/tim-logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="tim"/></a>,
  <a className="partners" href="https://bodyfuel.ie" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/bodyfuel-logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="bodyfuel"/></a>,
  <a className="partners" href="https://eko-light.com/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/milagro-logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="milagro"/></a>,
  <a className="partners" href="https://eko-light.com/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/eko-light.png" style={{margin:'20px'}} width="180px" height="90px" alt="eko-light"/></a>,
  // nowe - podobno nie znajduje
  <a className="partners" href="https://eko-light.com/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/foen.png" style={{margin:'20px'}} width="180px" height="90px" alt="Foen"/></a>,
  <a className="partners" href="https://myfamilyhome.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/logo_myfamilyhome.png" style={{margin:'20px'}} width="180px" height="90px" alt="My Family Home"/></a>,
  // z biblioteki mediów (złe proporcje miały być 200 x 100)
  <a className="partners" href="https://drmagic.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/drmagic.png" style={{margin:'20px'}} width="180px" height="90px" alt="Dr. Magic"/></a>,
  <a className="partners" href="https://mexen.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/mexen-logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="Mexen"/></a>,
  <a className="partners" href="https://conte.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/conte.png" style={{margin:'20px'}} width="180px" height="90px" alt="Conte"/></a>,
  <a className="partners" href="https://triumph.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/triumphxsloggi.png" style={{margin:'20px'}} width="180px" height="90px" alt="Triumph x Sloggi"/></a>,
  <a className="partners" href="https://revise.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/logo-revise.png" style={{margin:'20px'}} width="180px" height="90px" alt="Revise"/></a>,
  <a className="partners" href="https://brenor.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/brenor-logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="Brenor"/></a>,
  <a className="partners" href="https://melagu.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/melagu1.png" style={{margin:'20px'}} width="180px" height="90px" alt="Melagu"/></a>,
  <a className="partners" href="https://komak.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/komak.png" style={{margin:'20px'}} width="180px" height="90px" alt="Komak"/></a>,
  <a className="partners" href="https://allboards.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/allboards.png" style={{margin:'20px'}} width="180px" height="90px" alt="All boards"/></a>,
  <a className="partners" href="https://smartspace.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/logo-smartspace.png" style={{margin:'20px'}} width="180px" height="90px" alt="Smartspace"/></a>,
  <a className="partners" href="https://de-dietrich.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/bdr-thermea-group-dd-baxi_logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="BDR Thermea"/></a>,
  <a className="partners" href="https://multisunlogo.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/multisunlogo3.png" style={{margin:'20px'}} width="180px" height="90px" alt="Multisun"/></a>,
  <a className="partners" href="https://mikroel.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/mikroel.png" style={{margin:'20px'}} width="180px" height="90px" alt="Mikroel"/></a>,
  <a className="partners" href="https://pdca.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/pdca_logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="PDCA"/></a>,
  <a className="partners" href="https://zielonynurt.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="Zielony Nurt"/></a>,
  <a className="partners" href="https://wodamoda.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/wodamoda.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="WODAMODA"/></a>,
  //jeszze więcej logotypów + 30
  <a className="partners" href="https://castorama.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/castorama.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="castorama"/></a>,
  <a className="partners" href="https://blomus.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/blomus.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="blomus"/></a>,
  <a className="partners" href="https://ecorson.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/ecorson.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Ecorson"/></a>,
  <a className="partners" href="https://electro-welle.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/electro-welle.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Electro Welle"/></a>,
  <a className="partners" href="https://energia-port.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/energiaport.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Energia Port"/></a>,
  <a className="partners" href="https://grupa-rb.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/grupa-rb.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="GRUPA RB"/></a>,
  <a className="partners" href="https://homebook.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/homebook.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Homebook"/></a>,
  <a className="partners" href="https://horus.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/horus.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Horus"/></a>,
  <a className="partners" href="https://interdoor.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/interdoor.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Interdoor"/></a>,
  <a className="partners" href="https://krasnale.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/krasnale.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Krasnale"/></a>,
  <a className="partners" href="https://mangomica.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/mangomica.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Mangomica"/></a>,
  <a className="partners" href="https://marchewka.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/marchewka.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Marchewka"/></a>,
  <a className="partners" href="https://mii.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/mii.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Mii"/></a>,
  <a className="partners" href="https://minka.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/minka.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Minka"/></a>,
  <a className="partners" href="https://nad-odra.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/nad-odra.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Nad Odrą"/></a>,
  <a className="partners" href="https://neonail.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/neonail.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Neonail"/></a>,
  <a className="partners" href="https://o12.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/o12.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="o12"/></a>,
  <a className="partners" href="https://po.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/po.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="PO"/></a>,
  <a className="partners" href="https://power.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/power.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Power"/></a>,
  <a className="partners" href="https://simple-software.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/simple-software.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Simple software"/></a>,
  <a className="partners" href="https://sitting-bull.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/sitting-bull.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Sitting Bull"/></a>,
  <a className="partners" href="https://smaki-wroclawia.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/smaki-wroclawia.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Smaki Wrocławia"/></a>,
  <a className="partners" href="https://sofath.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/sofath.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Sofath"/></a>,
  <a className="partners" href="https://studio.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/studio.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Studio"/></a>,
  <a className="partners" href="https://trii.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/trii.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Trii"/></a>,
  <a className="partners" href="https://unit4.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/unit4.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Unit4"/></a>,
  <a className="partners" href="https://vitcas.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/vitcas.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Vitcas"/></a>,
  <a className="partners" href="https://volcano.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/volcano.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Volcano"/></a>,
  <a className="partners" href="https://wroclaw.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/wroc.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Wrocław"/></a>,

];





/*
function animateIcons(){





      let i = 0;

      setInterval(()=>{
        if (document.getElementById('atuty')!==null){
        let icons = document.getElementsByClassName('atut');
        for(let o=0;o<icons.length;o++){
          icons[o].style.animation = '';
        }
        if(i < 10){
          icons[i].style.animation='ollie 2s 1';
          i=i+1;
        }
        else{
          i=0;
          icons[i].style.animation='ollie 2s 1';
          i=i+1;
        }

      }}, 2000);


}
*/
